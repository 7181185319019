import React, { createContext, useContext, useEffect, useState } from "react";
import services from "../services/services";
const AppContext = createContext();

export const useAppContext = () => {
	return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
	const [showCookiePreferenceModal, setShowCookiePreferenceModal] =
		useState(false);
	const [showCookieBanner, setShowCookieBanner] = useState(
		localStorage.getItem("cookiePreference") ? false : true
	);
	const [cookiePreference, setCookiePreference] = useState({
		essential: false,
		performance: false,
		functional: false,
		targeting: false,
	});

	const [features, setFeatures] = useState({});
	const [loadingFeatures, setLoadingFeatures] = useState(false);

	const saveCookiePreference = (cookiePref) => {
		if (cookiePref) {
			const cookiePrefJSON = JSON.stringify(cookiePref);
			localStorage.setItem("cookiePreference", cookiePrefJSON);
			if (showCookieBanner) {
				setShowCookieBanner(false);
			}
		}
	};

	useEffect(() => {
		// Get user cookie preferences
		const storedCookiePreference = localStorage.getItem("cookiePreference");
		if (storedCookiePreference) {
			setCookiePreference(JSON.parse(storedCookiePreference));
		}
	}, []);

	return (
		<AppContext.Provider
			value={{
				features,
				setFeatures,
				loadingFeatures,
				setLoadingFeatures,
				showCookiePreferenceModal,
				showCookieBanner,
				setShowCookiePreferenceModal,
				cookiePreference,
				setCookiePreference,
				saveCookiePreference,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
