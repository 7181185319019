import React, { useEffect } from "react";
import services from "../../services/services";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const ProtectedRoute = ({ children }) => {
	let location = useLocation();
	const { setFeatures, setLoadingFeatures } = useAppContext();

	useEffect(() => {
		// Function to fetch features from the backend
		const fetchFeatures = () => {
			setLoadingFeatures(true);
			services
				.getFeatures()
				.then((response) => {
					setFeatures(response);
				})
				.finally(() => {
					setLoadingFeatures(false);
				});
		};

		fetchFeatures();
	}, []);

	if (!sessionStorage.getItem("ags_token")) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
};

export default ProtectedRoute;
