import React, { useEffect } from "react";
import { useState } from "react";
import {
	Button,
	Grid,
	Alert,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import Appbar from "../appbar/Appbar";
import Sidebar from "../sidebar/Sidebar";
import Dropzone from "../Dropzone";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";
import styles from "./uploadpage.module.scss";

import services from "../../services/services";

export default function UploadPage(props) {
	const {
		features: { set_file_size },
		loadingFeatures,
	} = useAppContext();
	const [submitClicked, setSubmitClicked] = useState(false);
	const [files, setFiles] = useState(null);
	const [region, setRegion] = useState("GLOBAL");
	const [fileSize, setFileSize] = useState(0);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [responseError, setResponseError] = useState({
		status: "",
		message: "",
	});

	const handleUpload = async () => {
		setResponseError({ status: "" });
		services
			.uploadFile(files, region)
			.then((res) => {
				navigate("/downloads", { state: { id: res.id } });
			})
			.catch((err) => {
				setSubmitClicked(false);
				setResponseError(err);
			});
	};

	useEffect(() => {
		if (!loadingFeatures && set_file_size.is_enabled) {
			// get file size limit from API
			services
				.getFileSizeLimit()
				.then((response) => {
					if (response) setFileSize(response);
				})
				.catch((e) => {
					setFileSize(0);
				});
		} else if (!loadingFeatures && !set_file_size.is_enabled) {
			setFileSize(50);
		}
	}, [loadingFeatures, set_file_size]);

	const handleFileSizeChange = (fileSize) => {
		setLoading(true);
		services
			.updateFileSizeLimit(fileSize)
			.then((response) => {
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
			});
	};

	return (
		<Grid
			container
			style={{
				height: "calc(100vh - 3.4rem)",
				background: "#F1F5F8",
				alignContent: "center",
				justifyContent: "center",
				marginTop: "-0.8rem",
				flexDirection: "column",
			}}
		>
			{loading && <LoadingSpinner></LoadingSpinner>}
			{responseError.status && (
				<Alert sx={{ m: "1rem auto" }} severity="error">
					{responseError.message}
				</Alert>
			)}
			<Grid
				item
				md={4}
				width={"32rem"}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"end"}
			>
				{!loadingFeatures &&
					set_file_size.is_enabled &&
					set_file_size.roles_allowed.includes(
						sessionStorage.getItem("user_role")
					) && (
						<FormControl
							variant="standard"
							sx={{ m: 1, minWidth: 150 }}
							size="small"
						>
							<InputLabel id="demo-select-small-label">
								Input File Size Limit
							</InputLabel>
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={fileSize}
								label="FileSize"
								onChange={(e) => {
									setFileSize(e.target.value);
									handleFileSizeChange(e.target.value);
								}}
							>
								<MenuItem value={5}>5 MB</MenuItem>
								<MenuItem value={25}>25 MB</MenuItem>
								<MenuItem value={50}>50 MB</MenuItem>
							</Select>
						</FormControl>
					)}
				<Grid
					item
					md={12}
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
					padding={2}
					sx={{
						minHeight: "18rem",
						width: "100%",
						borderStyle: "dashed",
						borderColor: "#4B286D",
						borderRadius: 4,
						borderWidth: "1px",
						background: "white",
					}}
				>
					<Dropzone
						files={files}
						setFiles={setFiles}
						maxFileSize={fileSize}
						region={region}
						setRegion={setRegion}
						isSubmitClicked={submitClicked}
					/>
					<Button
						className={styles.buttoncls}
						disabled={files ? false : true}
						onClick={() => {
							setSubmitClicked(!submitClicked);
							handleUpload();
						}}
						style={{ width: "auto" }}
					>
						Submit
					</Button>
				</Grid>
			</Grid>

			{/* <Grid item md={12} display={"flex"} justifyContent={"center"}></Grid>
      <Grid item md={12} display={"flex"} justifyContent={"center"}>
        <Button
          onClick={() => {
            navigate("/recent-files");
          }}
        >
          Processed Files
        </Button>
      </Grid> */}
		</Grid>
	);
}
