import React, { useState } from "react";
import { Link } from "@mui/material";

import styles from "./Footer.module.scss";
import { useAppContext } from "../../context/AppContext";

const Footer = () => {
	const { setShowCookiePreferenceModal } = useAppContext();

	return (
		<div className={styles.footerWrapper}>
			{/* <div>
				<Link component="button" variant="body" underline="hover" color="white">
					Privacy Policy
				</Link>
			</div> */}

			<div>
				<Link
					component="button"
					variant="body"
					underline="hover"
					color="white"
					onClick={() => setShowCookiePreferenceModal(true)}
				>
					Cookie Preferences
				</Link>
			</div>
		</div>
	);
};

export default Footer;
