import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { cookiePreferenceItems } from "../../constants";
import styles from "./CookieBanner.module.scss";
import { useAppContext } from "../../context/AppContext";
const CookieBanner = () => {
	const {
		setShowCookiePreferenceModal,
		saveCookiePreference,
		setCookiePreference,
	} = useAppContext();
	const handleClickNecessaryCookies = () => {
		const necessaryCookies = cookiePreferenceItems.reduce((acc, curr) => {
			acc[curr.id] = curr.necessary;
			return acc;
		}, {});

		setCookiePreference({ ...necessaryCookies });
		saveCookiePreference({ ...necessaryCookies });
	};
	const handleClickAllCookies = () => {
		const allCookies = cookiePreferenceItems.reduce((acc, curr) => {
			acc[curr.id] = curr.necessary;
			return acc;
		}, {});
		setCookiePreference({ ...allCookies });
		saveCookiePreference(allCookies);
	};

	return (
		<Box className={styles.boxContainer}>
			<div className={styles.contentContainer}>
				<Typography variant="body2" fontSize={12} component="span">
					TELUS and our partners use cookies to enhance website navigation,
					analyze website usage, and provide relevant advertising and
					personalized content, including on third party websites. You have
					control over these cookies, and can choose to "Accept all cookies",
					"Accept only necessary" cookies, or adjust your preferences by
					clicking "Manage cookies". Blocking some types of cookies may impact
					your experience of the site and the services we are able to offer. To
					learn more about how we and third parties use your data, read our{" "}
					<Link
						href="https://www.telus.com/en/about/privacy/cookies"
						target="_blank"
						rel="noopener"
						underline="hover"
					>
						Cookie Statement
					</Link>{" "}
					and our{" "}
					<Link
						href="https://www.telus.com/en/health/about-telus-health/privacy?linktype=ge-footer"
						target="_blank"
						rel="noopener"
						underline="hover"
					>
						Privacy Commitment
					</Link>
					.
				</Typography>
				<Box className={styles.buttonWrapper}>
					<button
						className={styles.button}
						onClick={() => setShowCookiePreferenceModal(true)}
					>
						Manage Cookies
					</button>
					<button
						className={styles.button}
						onClick={() => handleClickNecessaryCookies()}
					>
						Accept only necessary
					</button>
					<button
						className={styles.button}
						onClick={() => handleClickAllCookies()}
					>
						Accept all cookies
					</button>
				</Box>
			</div>
		</Box>
	);
};

export default CookieBanner;
