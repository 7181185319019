import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from "react";
import UploadPage from "./components/uploadPage/UploadPage";
import DownloadFile from "./components/downloadPage/DownloadPage";
import RecentFilesTable from "./components/recent-files-table/RecentFilesTable";
import AllRecentFiles from "./components/all-recent-files/AllRecentFilesTable";
import LoginScreen from "./components/Login/Login.component";
import Dashboard from "./components/admin-dashboard/Dashboard";
import MasterConfigTable from "./components/masterTable/masterTable";
// import Sidebar from "./components/sidebar/Sidebar";
import { SidebarLayout } from "./components/sidebar/SidebarLayout";
import UpdatePassword from "./components/admin-dashboard/updatePassword/updatePassword";
import ProtectedRoute from "./components/ProtectedRoutes/protectedRoutes";
import PageNotFound from "./components/page-not-found/PageNotFound";
import AuthorizedRoutes from "./components/ProtectedRoutes/AuthorizedRoutes";
import { ThemeProvider } from "@mui/material/styles";
import { LightTheme } from "./Utils/LightTheme";
import FAQ from "./components/faq/FAQPage";
import LookerTable from "./components/looker-table/LookerTable";
import services from "./services/services";
import { AppProvider } from "./context/AppContext";
import RootLayout from "./layouts/RootLayout.js";

function App() {
	let t = null;

	useEffect(() => {
		window.addEventListener("mousemove", onMouseMove);
	}, []);

	const onMouseMove = () => {
		if (t) {
			clearTimeout(t);
		}

		t = setTimeout(() => {
			if (window.location.pathname !== "/login") {
				services.logout();
				sessionStorage.removeItem("ags_token");
				sessionStorage.removeItem("sso_token");
				sessionStorage.removeItem("forgerock_token");
				sessionStorage.removeItem("tenant_group");
				localStorage.setItem(
					"sessionInfo",
					JSON.stringify({ expired: true, isNotify: false })
				);
				window.open("/login", "_self");
			}
		}, 300000);
	};

	return (
		<ThemeProvider theme={LightTheme}>
			<AppProvider>
				<RootLayout>
					<Route path="/login" element={<LoginScreen />} />
					<Route element={<SidebarLayout />}>
						<Route path="/page-not-found" element={<PageNotFound />} />
					</Route>

					<Route element={<SidebarLayout />}>
						<Route
							path="/"
							element={
								<ProtectedRoute>
									<UploadPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/downloads"
							element={
								<ProtectedRoute>
									<DownloadFile />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/downloads"
							element={
								<ProtectedRoute>
									<DownloadFile />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/faq"
							element={
								<ProtectedRoute>
									<FAQ />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/recent-files"
							element={
								<ProtectedRoute>
									<RecentFilesTable />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin-dashboard"
							element={
								<ProtectedRoute>
									<AuthorizedRoutes>
										<Dashboard />
									</AuthorizedRoutes>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/master-table"
							element={
								<ProtectedRoute>
									<AuthorizedRoutes>
										<MasterConfigTable />
									</AuthorizedRoutes>
								</ProtectedRoute>
							}
						></Route>
						<Route
							path="/update-password"
							element={
								<ProtectedRoute>
									<UpdatePassword />
								</ProtectedRoute>
							}
						></Route>
						<Route
							path="/all-recent-files"
							element={
								<ProtectedRoute>
									<AuthorizedRoutes>
										<AllRecentFiles />
									</AuthorizedRoutes>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/looker-table"
							element={
								<ProtectedRoute>
									<LookerTable />
								</ProtectedRoute>
							}
						></Route>
					</Route>
				</RootLayout>
			</AppProvider>
		</ThemeProvider>
	);
}

export default App;
