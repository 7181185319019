import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	Typography,
	Link,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Switch,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useAppContext } from "../../context/AppContext";
import { cookiePreferenceItems } from "../../constants";
import styles from "./CookiePreference.module.scss";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	maxHeight: "90%",
	overflowY: "auto",
	transform: "translate(-50%, -50%)",
	width: "40%",
	bgcolor: "background.paper",
	borderRadius: "10px",
	boxShadow: 24,
	p: 2,
};

const CookiePreferenceModal = () => {
	const [expandedAccordion, setExpandedAccordion] = useState(null);
	const {
		setShowCookiePreferenceModal,
		showCookiePreferenceModal,
		saveCookiePreference,
		cookiePreference,
		setCookiePreference,
	} = useAppContext();

	const handleToggleAccordion = (e, accordionId) => {
		e.stopPropagation();
		if (expandedAccordion == accordionId) {
			setExpandedAccordion(null);
		} else {
			setExpandedAccordion(accordionId);
		}
	};

	const handleToggleSwitch = (e, accordionId) => {
		e.stopPropagation();
		setCookiePreference({
			...cookiePreference,
			[accordionId]: !cookiePreference[accordionId],
		});
	};

	const onSaveCookiePreference = () => {
		setShowCookiePreferenceModal(false);
		saveCookiePreference(cookiePreference);
	};

	return (
		<Modal
			open={showCookiePreferenceModal}
			onClose={() => setShowCookiePreferenceModal(false)}
		>
			<Box sx={style}>
				<Box display={"flex"} flexDirection={"column"} gap="1em">
					<Typography fontSize="20px" fontWeight="bold">
						About your Privacy and Cookies Settings
					</Typography>
					<Typography variant="body2">
						Cookies are important to the proper functioning of a site and to
						provide you with a more personalized user experience. We want you to
						make informed choices; click on each category below to learn more
						about why we use cookies and then manage your own preferences. For
						more detailed information, please see our{" "}
						<Link
							href="https://www.telus.com/en/about/privacy/cookies"
							target="_blank"
							rel="noopener"
							underline="hover"
						>
							Cookie Statement
						</Link>
					</Typography>
					<Box>
						{cookiePreferenceItems.map((item) => (
							<Accordion
								key={item.id}
								expanded={expandedAccordion == item.id}
								onChange={(e) => handleToggleAccordion(e, item.id)}
							>
								<AccordionSummary
									expandIcon={
										<KeyboardArrowDownOutlinedIcon sx={{ color: "#66cc00" }} />
									}
									sx={{ flexDirection: "row-reverse", gap: "1em" }}
									ic
								>
									<Box
										display="flex"
										alignItems="center"
										width="100%"
										justifyContent="space-between"
									>
										<Typography variant="subtitle2">{item.title}</Typography>
										<Switch
											aria-label="essential-switch"
											checked={
												cookiePreference
													? cookiePreference[item.id]
													: item.defaultChecked
											}
											disabled={item.disabled}
											onClick={(e) => e.stopPropagation()}
											onChange={(e) => handleToggleSwitch(e, item.id)}
										/>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Typography variant="body2">{item.description}</Typography>
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
					<Box
						display="flex"
						justifyContent="flex-end"
						justifyItems="flex-end"
						gap="1em"
						width="100%"
					>
						<button
							onClick={() => setShowCookiePreferenceModal(false)}
							className={styles.buttonClose}
						>
							Close
						</button>
						<button
							onClick={() => onSaveCookiePreference()}
							className={styles.buttonSave}
						>
							Save
						</button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default CookiePreferenceModal;
