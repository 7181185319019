import React, { useEffect, useState, useRef } from "react";
import styles from "./Login.module.scss";
import logo from "../../assets/logo.svg";
import { Grid, Box, Alert, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import services from "../../services/services";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";
import { EMAIL_VALIDATION_REGEX } from "../../Utils/constants";
import { useAppContext } from "../../context/AppContext";

const LoginScreen = () => {
	const { cookiePreference, setShowCookiePreferenceModal } = useAppContext();
	const [showPassword, setShowPassword] = useState(false);
	const [allowLogin, setAllowLogin] = useState(false);
	const [errorState, setErrorState] = useState({
		error: false,
		errorMessage: "",
		key: "",
	});
	const [responseError, setResponseError] = useState({
		status: "",
		message: "",
	});
	const [loginState, setLoginState] = useState({
		username: "",
		password: "",
	});
	const [loading, setLoading] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const navigate = useNavigate();
	const app = useRef();
	const auth = useRef();
	const provider = useRef();

	function captureCredentials(e, type) {
		// console.log("captureCredentials", loginState);

		if (type === "username") {
			const emailId = e.target.value.trim();
			setLoginState((prevState) => ({
				...prevState,
				username: emailId,
			}));
			if (!emailId.match(EMAIL_VALIDATION_REGEX)) {
				return setErrorState((prevState) => ({
					...prevState,
					error: true,
					errorMessage: "Enter Valid Email Id.",
					key: "username",
				}));
			} else {
				return setErrorState((prevState) => ({
					...prevState,
					error: false,
					errorMessage: "",
					key: "username",
				}));
			}
		} else {
			setLoginState((prevState) => ({
				...prevState,
				password: e.target.value.trim(),
			}));
		}
	}

	function handleGoogleSignIn(response) {
		setLoading(true);
		verifyLogin(response);
	}

	// BE call to verify the user that just signed in
	const verifyLogin = async (res) => {
		services
			.loginReq(res)
			.then((response) => {
				// backend response once wrapped in JWT. BE logic needs rework
				if (response && response.access_token) {
					sessionStorage.setItem("ags_token", response.access_token);
					sessionStorage.setItem("refresh", response.refresh_token);
					sessionStorage.setItem(
						"user_info",
						response.first_name + " " + response.last_name
					);
					sessionStorage.setItem("user_role", response.role);
					sessionStorage.setItem("user_image", response.image);
					// Set tenant_group
					sessionStorage.setItem("tenant_group", response.tenant_group);
					// if (response.first_login === true) {
					//   navigate("/update-password");
					if (response.role.toUpperCase() === "ADMIN") {
						navigate("/admin-dashboard");
					} else {
						navigate("/");
					}
				}
				setLoading(false);
			})
			.catch((err) => {
				setResponseError(err);
				setLoading(false);
			});
	};

	const PASSWORD_LENGTH = 30;

	// Internal user sign in
	useEffect(() => {
		if (cookiePreference && cookiePreference.essential) {
			services.getFirebaseCredentials().then((res) => {
				/*global google*/
				google.accounts.id.initialize({
					client_id: res.clientId,
					callback: handleGoogleSignIn,
				});

				// eslint-disable-next-line no-undef
				google.accounts.id.renderButton(document.getElementById("signInDiv"), {
					theme: "outlined",
					size: "large",
					width: "260px",
					text: "Sign in with Telus Health",
				});

				var firebaseConfig = {
					apiKey: res.apiKey,
					authDomain: res.authDomain,
				};
				app.current = initializeApp(firebaseConfig);
				auth.current = getAuth(app.current, {});
				provider.current = new OAuthProvider(res.providerId);
				provider.current.addScope("email");
				provider.current.addScope("openid");
				provider.current.addScope("fr:idm:*");
				provider.current.setCustomParameters({
					scopes: "email openid fr:idm:*",
				});
			});
		}
	}, [cookiePreference]);

	// external user sign in
	function handleExternalSignIn() {
		// let email = loginState.username; //"testuser@test.com";
		// let password = loginState.password; //"asdf123";

		signInWithPopup(auth.current, provider.current)
			.then((result) => {
				// Get the ForgeRock access token and ID Token
				const credential = OAuthProvider.credentialFromResult(result);
				sessionStorage.setItem("forgerock_token", credential.accessToken);
				sessionStorage.setItem("idToken", credential.idToken);
				verifyLogin(credential);
			})
			.catch((error) => {
				// Handle error.
				setResponseError({
					status: "500",
					message: "Error while communicating with Auth provider",
				});
				alert(error);
				setLoading(false);
			});
	}

	return (
		<Grid container direction={"column"} className={styles.body}>
			{loading && <LoadingSpinner></LoadingSpinner>}
			{responseError.status && (
				<Alert sx={{ mb: "1rem" }} severity="error">
					{responseError.message}
				</Alert>
			)}
			<Grid item md={3} className={styles.loginContainer}>
				<Grid item md={12} className={styles.logoPlaceholder}>
					<img
						width="100%"
						height="100%"
						src={logo}
						alt="Telus Health"
						// sx={{ flexGrow: 1 }}
					/>
				</Grid>
				<Grid item md={12}>
					{cookiePreference && cookiePreference.essential ? (
						<Box mt={2}>
							<button
								type="submit"
								className={styles.loginButton}
								onClick={() => {
									setLoading(true);
									handleExternalSignIn(loginState);
								}}
							>
								Sign in with Partner ID
							</button>

							<div id="signInDiv"></div>
						</Box>
					) : (
						<Box
							mt={2}
							display="flex"
							flexDirection="column"
							gap="1em"
							justifyContent="center"
						>
							<Typography variant="subtitle2" fontWeight="bold">
								Enable essential cookies to login
							</Typography>
							<button
								onClick={() => setShowCookiePreferenceModal(true)}
								className={styles.buttonOpenCookiePref}
							>
								Cookie Preferences
							</button>
						</Box>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LoginScreen;
