import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styles from "./RootLayout.module.scss";
import CookieBanner from "../components/cookie-banner/CookieBanner";
import Footer from "../components/footer/Footer";
import CookiePreferenceModal from "../components/cookie-preference-modal/CookiePreferenceModal";
import { useAppContext } from "../context/AppContext";
const RootLayout = ({ children }) => {
	const { showCookieBanner } = useAppContext();
	return (
		<div className={styles.rootWrapper}>
			<div className={styles.mainContentWrapper}>
				<Router>
					<div className={styles.routesWrapper}>
						<Routes>{children}</Routes>
					</div>
				</Router>
			</div>
			<footer className={styles.footerWrapper}>
				<Footer />
			</footer>
			<CookiePreferenceModal />
			{showCookieBanner && <CookieBanner />}
		</div>
	);
};

export default RootLayout;
