export const cookiePreferenceItems = [
	{
		id: "essential",
		title: "Essential",
		necessary: true,
		defaultChecked: true,
		disabled: false,
		description: `These cookies are necessary for the website to function as intended. Without these cookies, the website will not operate so they cannot be disabled.`,
	},
	{
		id: "performance",
		title: "Performance",
		necessary: false,
		defaultChecked: false,
		disabled: true,
		description: `These cookies allow us to count visits and identify traffic sources so we can measure and improve website performance. They help us identify which pages are the most and least popular and see how users move around the site. If you do not allow these cookies, we will not know what content is most valued and how often unique visitors return to the site, making it difficult to improve the information we offer to you.`,
	},
	{
		id: "functional",
		title: "Functional",
		necessary: false,
		defaultChecked: false,
		disabled: true,
		description: `These cookies allow us to provide enhanced functionality and personalization. It is through their use that we are able to remember your settings and preferences, such as your username, your preferred language, your country, or any other saved preference. Without them, your experience on our website may not be as satisfying to you as it could have been.`,
	},
	{
		id: "targeting",
		title: "Targeting",
		necessary: false,
		defaultChecked: false,
		disabled: true,
		description: `These cookies may be set through our site by us or our advertising or social media partners. These allow us and these third parties to better understand your interests, provide you with personalized content and offers on our websites and third party websites and enable social media or other third party features on our website. If you do not allow these cookies, you will experience less relevant advertising and offers, and may not be able to use social media or third party features on our website.`,
	},
];
